var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h2',[_vm._v(_vm._s(_vm.d.name))]),_c('v-data-table',{attrs:{"items":_vm.athletes,"headers":[
      { text: 'Turnerin', value: 'name', sortable: false },
      { text: 'Startnummer', value: 'order', sortable: false, align: 'center' },
      { text: 'Hinzufügen', value: 'add', sortable: false, align: 'center' },
      { text: 'Entfernen', value: 'del', sortable: false, align: 'center' }
    ],"items-per-page":-1},scopedSlots:_vm._u([{key:"item.order",fn:function({item}){return [_c('base-edit-dialog',{attrs:{"value":item.order,"id":item._id,"label":"Reihenfolge"},on:{"input":({ value, id: iid }) => _vm.updateorder(iid, value)}})]}},{key:"item.add",fn:function({item}){return [(!item.active && _vm.numactive < _vm.maxturner(_vm.e))?_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.add(item._id)}}},[_c('v-icon',[_vm._v("far fa-plus")])],1):_vm._e()]}},{key:"item.del",fn:function({item}){return [(item.active && !item.used)?_c('v-btn',{attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.del(item._id)}}},[_c('v-icon',[_vm._v("far fa-minus")])],1):_vm._e()]}}])}),_c('h3',[_vm._v("Hinweise:")]),_vm._m(0)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("Turner, bei denen bereits eine Wertung eingegeben wurde, können nicht mehr entfernt werden.")]),_c('li',[_vm._v("Um diese Turner zu entfernen, muss die zugehörige Wertung (über den Ergebnisse-Reiter) gelöscht werden.")]),_c('li',[_vm._v("Die Reihenfolge kann durch einen Klick auf die Zahl angepasst werden.")])])
}]

export { render, staticRenderFns }